

  .ant-upload.ant-upload-select-picture-card {
    width: 100% !important;
    height: 136px !important;
  }
.ant-upload-list-item {
    width: 100% !important;
    height: 136px !important;
}

.ant-upload-list-picture-card-container {

    width: 100% !important;
    height: 136px !important;
    
}



