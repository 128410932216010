@import '~antd/dist/antd.css';


.centered-cell {
  text-align: center;
}

.ant-list-item-meta-description {
  white-space: pre-wrap;
}

.site-layout-background {
  background: #fff;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
  min-height: 280;
}

.ant-page-header {
  padding: 16px 0px 16px 24px !important;
}

.main-layout {
  padding: 0 24px 24px;
}

.ant-breadcrumb {
  margin: 16px 0;
}

.logo {
  
  height: 31px;
  
  margin: 12px 24px 16px 0px;
  float: left;
}

.header {
  padding: 0 20px;
}

.tight-form-item.ant-form-item {
  margin-bottom: 0px;
}

.no-space-form-item.ant-form-item {
  margin-bottom: 0px;
}

.ant-table {
  display: block;

  @media screen and (max-width: 600px) {
    &-thead {
      display: none;
    }

    &-thead>tr,
    &-tbody>tr {

      th,
      td {
        &:first-of-type {
          padding-top: 1rem;
        }

        &:last-of-type {
          padding-bottom: 1rem;
        }
      }

      >th,
      >td {
        display: block;
        width: auto !important;
        border: none;
        padding: 0 1rem;
        font-size: 1.1rem;

        &:last-child {
          border-bottom: 1px solid #eee;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .main-layout {
    padding: 0px;
  }
  .ant-breadcrumb {
    margin: 16px 16px;
  }
}


@media print
{    
 
  
  .no-print, .no-print *
    {
        display: none !important;
    }

    .main-layout {
      padding: 0px 0px 0px 0px !important;
      background: #fff !important;
    }

    .ant-layout {
      overflow-x: visible !important;
      padding: 0px 0px 0px 0px !important;
    }

    .ant-layout-content {
     
      padding: 0px !important;
    }
}
  