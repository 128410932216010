.equipment-uploader > .ant-upload {
  width: 228px;
  height: 228px;
}


.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 16px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: rgb(39, 39, 39);
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
  [data-theme="dark"] .dynamic-delete-button {
    color: rgba(255,255,255,.45);
  }
  [data-theme="dark"] .dynamic-delete-button:hover {
    color: rgba(255,255,255,.65);
  }


@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0px !important;
  }

  .last-part.tight-form-item.ant-form-item {
    margin-bottom:12px;
  }
}


    