.equipment-uploader > .ant-upload {
    width: 228px;
    height: 228px;
  }
  


  .equipment-list-item {
    cursor: pointer;
}
  
  @media (max-width: 575px) {
    .ant-form-item .ant-form-item-label {
      padding: 0px !important;
    }
  
    .last-part.tight-form-item.ant-form-item {
      margin-bottom:12px;
    }
  }
  
  
      