.equipment-cell {
    cursor: pointer;
}

table.fixed {
    table-layout: fixed;
  }

td.truncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

td.completed-column {
  width: 100px;
}